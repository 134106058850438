import { Box } from "@mui/material";
import DataCardContainer from "../../components/Common/DataCard/DataCardContainer";
import FeedApiHeroSection from "../../components/FeedApi/FeedApiHeroSection";
import "../../components/FeedApi/feedApiStyle.scss";

const FeedApi = () => {
  return (
    <>
      <FeedApiHeroSection />
      <Box className="feedApi_page">
        <div
          className="headingCSS mar-btm-30px feedApi--cardSection--heading"
          style={{ width: "100%", maxWidth: "1340px" }}
        >
          <h1 className="feedApi_heading">
            {" "}
            Why Integrate our &nbsp;
            <span className="headingSpan">API</span> ?
          </h1>
        </div>
        <DataCardContainer />
      </Box>
    </>
  );
};

export default FeedApi;
