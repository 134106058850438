import { Card, Col, Container, Row } from "react-bootstrap";
import { Image } from "react-bootstrap";
import realTime from "../../images/LandingPage/RealTime/manWithLaptop.png";
import graphChart from "../../images/LandingPage/RealTime/graph.png";
import statChart from "../../images/LandingPage/RealTime/stats.png";
import bottomLeftCircle from "../../images/LandingPage/bottomLeft.png";
import topRightCircle from "../../images/LandingPage/topRightCircle.png";
import manWithLaptop from "../../images/LandingPage/FAQSection/withAllImagesCombined.png";
import icon1 from "../../images/realTime_icon-1.png";
import icon2 from "../../images/realTime_icon-3.png";
import icon3 from "../../images/realTime_icon-2.png";
import icon4 from "../../images/realTime_icon-4.png";
import ScheduleDemoButton from "../Common/ScheduleButton/ScheduleDemoButton";
import FAQs from "../FAQ/FAQs";
import { Link } from "react-router-dom";
import { MPULSE_WEB_URL } from "../../Constants/Constant";
import EngageYourDataSection from "./EngageYourDataSection/EngageYourDataSection";
import CompareYourbrandSection from "./CompareyourBrandSection/CompareYourbrandSection";

const CardSection = () => {
  const renderIconSection = (src: any, description: any) => (
    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
      <div className="sectionHeadDiv mar-btm-20px">
        <div className="sectionIconCSS">
          <Image className="cardIcon" src={src} alt={description} />
        </div>
        <div className="cardDescription">{description}</div>
      </div>
    </Col>
  );

  return (
    <div>
      {/* Unleash the Power of mPulse starts */}
      <div className="unlease_power_mpulse_bg">
        <Container>
          <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-delay="500"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="true"
          >
            <div className="headingCSS mar-btm-30px">
              <h1>
                Unleash the Power of
                <span className="headingSpan"> mPulse</span>
              </h1>
            </div>
            <p className="text-center p-tag-center">
              Gain valuable insights into how customers perceive your products,
              services, and overall brand experience.
            </p>
          </div>
        </Container>

        <Container>
          <Row className="padding-none row-gutter-x-y-none alignCards">
            <Col
              xs={12}
              sm={12}
              md={8}
              lg={6}
              className="padding-zero flexAlignCenterNew"
            >
              <div
                className="container-real-time"
                data-aos="slide-left"
                data-aos-easing="ease-in-out"
                data-aos-duration="1200"
                data-aos-once="true"
              >
                <img
                  className="realTimeChart shake-Y"
                  src={graphChart}
                  alt="graph chart"
                />
                <img
                  className="realStatShow shake"
                  src={statChart}
                  alt="graph chart"
                />
                <img
                  className="realTimeEllipse1 rotate-XY"
                  src={bottomLeftCircle}
                  alt="ellipse chart"
                />
                <img
                  className="realTimeEllipse2 rotate-XY"
                  src={topRightCircle}
                  alt="ellipse2 chart"
                />
                <Image
                  className="d-block w-100"
                  src={realTime}
                  alt="First slide"
                  fluid
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <div
                data-aos="slide-up"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                className="height-100 paddingLeft"
                data-aos-once="true"
              >
                <Card.Body className="cardCenter">
                  <Card.Title className="cardTitle mar-btm-20px">
                    Real Time <span>Sentiment Analysis</span>
                  </Card.Title>
                  <Card.Text className="cardDescription mar-btm-30px p-tag-left">
                    Experience how customers perceive your products, services,
                    and overall brand experience.
                  </Card.Text>
                  <Row className="padding-none margin-none row-gutter-x-y-none">
                    {renderIconSection(icon1, "Understand Market Perception")}
                    {renderIconSection(icon2, "Monitor social media mentions")}
                    {renderIconSection(icon3, "Identify Trends")}
                    {renderIconSection(icon4, "Visualize dynamic data")}
                  </Row>
                  <Card.Text className="cardDescription mar-btm-30px p-tag-left">
                    Anticipate your customers needs, and build strong
                    relationships that drive loyalty.
                  </Card.Text>
                  <Link key="Home" to={`${MPULSE_WEB_URL}`} color="transparent">
                    <ScheduleDemoButton
                      btnTitle="Get Started"
                      btnClass="purpleBtn"
                    />
                  </Link>
                </Card.Body>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Unleash the Power of mPulse ends */}

      {/* Engage your Data. starts */}
      <EngageYourDataSection />
      {/* Engage your Data. ends */}

      {/* Compare Your Brand Section starts */}
      <CompareYourbrandSection />
      {/* Compare Your Brand Section ends */}

      <div className="faqBG">
        <Container>
          <div>
            <div className="headingCSS">
              <h1>Frequently Asked Questions</h1>
            </div>
          </div>
        </Container>
        <Container>
          <Row className="padding-none margin-none row-gutter-x-y-none pad-top-md alignCards">
            <Col xs={12} sm={12} md={12} lg={6} className="">
              <div
                data-aos="slide-up"
                data-aos-easing="ease-in-out"
                data-aos-duration="1500"
                className="height-100 paddingLeft paddingRight"
                data-aos-once="true"
              >
                <FAQs />
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} className="padding-zero">
              <div
                data-aos="slide-right"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                className="container-FAQ-image"
                data-aos-once="true"
              >
                <Image
                  className="d-block"
                  src={manWithLaptop}
                  alt="First slide"
                  fluid
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CardSection;
