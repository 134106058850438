import React, { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import Layout from "./components/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.scss";
import "./clash-display-font-css.css";
import scrollTopIcon from "./images/scrollTop.png";
import "./Styles/global.scss";
import BlogSingle from "./pages/Blogs/BlogsSinglePage/BlogsSingle";
import NotFound404 from "./components/Common/PageNotFound";
// import "./Styles/animations.css";
// import AOS from "aos";
// import "aos/dist/aos.css";

function App() {
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);

  // window.addEventListener("load", () => {
  //   AOS.init({
  //     duration: 1000,
  //     offset: 100,
  //   });
  // });

  const theme = createTheme({
    palette: {
      primary: {
        light: "#675AFD",
        main: "#675AFD",
        dark: "#005db0",
        contrastText: "#000",
      },
      secondary: {
        main: "#0473EA",
        light: "#0473EA",
        dark: "#00867d",
        contrastText: "#000",
      },
    },
  });

  /* const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible); */

  /* useEffect(() => {
    AOS.init({
      offset: 200,
      // duration: 500, // Animation duration in milliseconds
      once: false, // Only animate elements once
    });
  }, []); */

  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      setIsScrollButtonVisible(scrolled > 300);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsScrollButtonVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    const scrollButton = document.getElementById("scrollTop");
    if (scrollButton) {
      observer.observe(scrollButton);
    }

    window.addEventListener("scroll", toggleVisible);

    return () => {
      if (scrollButton) {
        observer.unobserve(scrollButton);
      }
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            {appRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            ))}
            <Route
              key="blog-single-desc-route"
              path="/blogs/:slug"
              element={BlogSingle}
              Component={BlogSingle}
            />
            <Route key="pageNotFound" path="*" element={NotFound404} Component={NotFound404} />
          </Routes>
        </Layout>
      </Router>
      {isScrollButtonVisible && (
        <div id="scrollTop" className="scrollTop">
          <img
            onClick={scrollToTop}
            className="scrollTopIcon"
            src={scrollTopIcon}
            alt="scrollTop Icon"
          />
        </div>
      )}
      {/* <div id="scrollTop" className="scrollTop">
        <img
          onClick={scrollToTop}
          style={{ display: visible ? "inline" : "none" }}
          className="scrollTopIcon"
          src={scrollTopIcon}
          alt="scrollTop Icon"
        />
      </div> */}
    </ThemeProvider>
  );
}

export default App;
