import { Box } from "@mui/material";
import BannerLowerSection from "../../Common/LowerBanner/BannerLowerSection";
import DataCardContainer from "../../Common/DataCard/DataCardContainer";
import "./style.scss";

const DataFeedApiSection = () => {
  return (
    <Box className="feedApi_box">
      <BannerLowerSection
        bannerSectionData={{
          title: "Revolutionize your decision making process.",
          subtitle: "Try our flagship product mPulse today!",
        }}
      />

      <Box className="feedApi_text">
        <div className="headingCSS mar-btm-30px">
          <h1>
            {" "}
            Data Feed &nbsp;
            <span className="headingSpan">API</span>
          </h1>
        </div>
        <p className="text-center p-tag-center">
          Seamlessly integrate our API and access real-time insights to fuel
          your brand's success
        </p>

        <DataCardContainer />
      </Box>
    </Box>
  );
};

export default DataFeedApiSection;
