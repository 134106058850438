import { Container } from "react-bootstrap";
import ScheduleDemoButton from "../ScheduleButton/ScheduleDemoButton";
import { Link } from "react-router-dom";
import thinSpring from "../../../images/Common/thinSpring.png";
import thickSpring from "../../../images/Common/thickSpring.png";
import { MPULSE_APPLICATION_URL } from "../../../Constants/Constant";
import "./style.scss";

const BannerLowerSection = ({ bannerSectionData }: any) => {
  return (
    <div className="speakExpertsSection">
      <img src={thickSpring} className="left_curl shake" alt="left curl" />

      <Container fluid className="banner-lower">
        <div className="banner-lower">
          <Container>
            <h1 className="h1ExpertsSection mar-bot-30px">
              {bannerSectionData?.title}
            </h1>
            <p className="pExpertSections mar-btm-15px">
              {bannerSectionData?.subtitle}
            </p>
            <Link
              key="Home"
              to={`${MPULSE_APPLICATION_URL}/auth/schedule-demo`}
              color="transparent"
              //target="_blank"
            >
              <ScheduleDemoButton btnTitle="Get in Touch" btnClass="whiteBtn" />
            </Link>
          </Container>
        </div>
      </Container>
      <img src={thinSpring} className="right_curl shake" alt="right curl" />
    </div>
  );
};

export default BannerLowerSection;
