import { ReactElement, FC } from "react";
import Banner from "../../components/Common/EmpoweringBanner/Banner";
import CardSection from "../../components/Sections/HomeCardSection";
import BannerHome from "../../components/Common/HomeBanner/BannerHome";
import DataFeedApiSection from "../../components/Sections/DataFeedApiSection/DataFeedApiSection";

const Home: FC<any> = (): ReactElement => {
  return (
    <>
      <BannerHome />
      <Banner />
      <CardSection />
      <DataFeedApiSection />
    </>
  );
};

export default Home;
