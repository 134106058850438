import { useState } from "react";
import { Accordion, Card, Button, Collapse } from "react-bootstrap";
import accordionData from "./faq.json";
import "./style.scss";

interface AccordionItemProps {
  index: number;
  expandedIndex: any;
  handleToggle: (index: number) => void;
  question: string;
  answer: string;
}

const AccordionItem = ({
  index,
  expandedIndex,
  handleToggle,
  question,
  answer,
}: AccordionItemProps) => (
  <div className="mar-btm-20px">
    <Card.Header
      className={`accordionHead ${
        expandedIndex === index ? "activeAccodion" : ""
      }`}
    >
      <Button
        className="btnFaqsBlock"
        variant="link"
        onClick={() => handleToggle(index)}
        aria-expanded={expandedIndex === index}
        aria-controls={`accordion-item-${index}`}
      >
        <span>{question}</span>
        {expandedIndex === index ? (
          <i className="bi bi-caret-up-fill"></i>
        ) : (
          <i className="bi bi-caret-down-fill"></i>
        )}
      </Button>
    </Card.Header>
    <Collapse className="accordionContent" in={expandedIndex === index}>
      <div id={`accordion-item-${index}`}>
        <Card.Body className="faqText">{answer}</Card.Body>
      </div>
    </Collapse>
  </div>
);

const MyAccordion = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);

  const handleToggle = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(0);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <Accordion defaultActiveKey="0">
      {accordionData.map((item, index) => (
        <AccordionItem
          key={index}
          index={index}
          expandedIndex={expandedIndex}
          handleToggle={handleToggle}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </Accordion>
  );
};

export default MyAccordion;
