import slide01 from "../../../images/actionable_insights_icon.svg";
import slide02 from "../../../images/harness_power_data_icon.svg";
import slide03 from "../../../images/machine_learning_technologies_icon.svg";

export const cardsData = [
    {
      title: "Actionable Insights",
      cardIcon: slide01,
      description:
        "Transform real-time data processing and predictive modeling into actionable decision-making",
    },
    {
      title: "Harness the Power of Data",
      cardIcon: slide02,
      description:
        "Unlock the potential of your data with our data-driven solutions.",
    },
    {
      title: "Machine Learning Technologies",
      cardIcon: slide03,
      description:
        "Leverage the latest machine learning algorithms for intelligent insights",
    },
  ];