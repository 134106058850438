import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import slide03 from "../../../images/mMatrix_compare_brand_image.png";
import clip from "../../../images/mMatrix_Clips.png";
import thickSpring from "../../../images/Common/thickSpring.png";
import ScheduleDemoButton from "../../Common/ScheduleButton/ScheduleDemoButton";
import { MPULSE_WEB_URL } from "../../../Constants/Constant";
import { Hidden } from "@mui/material";

const renderImageSection = () => (
  <Col
    xs={12}
    sm={12}
    md={8}
    lg={6}
    className="padding-zero flexAlignCenterNew mar-btm-30px"
  >
    <div
      data-aos="slide-left"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
      style={{ position: "relative" }}
      data-aos-once="true"
    >
      <Hidden mdUp>
        <img
          src={thickSpring}
          className="graphSectionCurl shake-Y"
          alt="spring curl"
        />
      </Hidden>
      <Hidden mdDown>
        <img
          src={thickSpring}
          className="graphSectionCurl shake-Y"
          alt="spring curl"
        />
      </Hidden>
      <Image
        className="d-block w-100"
        src={slide03}
        alt="Slide"
        fluid
        style={{ objectFit: "cover" }}
      />
      <Image className="clipCSS shake-Y" src={clip} alt="Clip" />
    </div>
  </Col>
);
const CompareYourbrandSection = () => {
  return (
    <div className="dashboards_reportings_BG">
      <Container>
        <Row className="padding-none margin-none row-gutter-x-y-none cardPadding alignCards">
          {renderImageSection()}
          <Col xs={12} sm={12} md={12} lg={6} className="mar-top-30px">
            <div
              data-aos="slide-up"
              data-aos-easing="ease-in-out"
              data-aos-duration="1200"
              className="height-100 paddingLeft"
              data-aos-once="true"
            >
              <Card.Body
                className="cardCenter"
                style={{ justifyContent: "flex-start" }}
              >
                <Card.Title className="cardTitle">
                  Compare Your Brand To <span>The Competition</span>
                </Card.Title>
                <hr style={{ width: "95%" }} />
                <Card.Text className="cardDescription">
                  <div className="sectionHeadDiv mar-btm-30px">
                    <div className="cardTitle">
                      Cutting-Edge Products for Data-Driven Decision-Making
                    </div>
                  </div>
                  <ul>
                    <li>
                      Unearth underlying trends to adapt strategies and stay one
                      step ahead in the market
                    </li>
                    <li>
                      Customize dashboards for real-time data visualization and
                      interaction
                    </li>
                    <li>Early detection of issues for proactive resolution</li>
                    <li>
                      Leverage the latest machine learning algorithms for
                      intelligent insights.
                    </li>
                  </ul>
                </Card.Text>
                <Link key="Home" to={`${MPULSE_WEB_URL}`} color="transparent">
                  <ScheduleDemoButton
                    btnTitle="Get Started"
                    btnClass="purpleBtn"
                  />
                </Link>
              </Card.Body>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CompareYourbrandSection;
