import { ReactElement, FC } from "react";
import { Hidden } from "@mui/material";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Image } from "react-bootstrap";
import slide02 from "../../images/generic-male.png";
import slide03 from "../../images/generic-female.png";
import "./About.scss";
import { Link } from "react-router-dom";
import ScheduleDemoButton from "../../components/Common/ScheduleButton/ScheduleDemoButton";
import Banner from "../TheCompleteSolution/Banner";
import circuitImage from "../../images/AboutUs/Banner/circuit_system.png";
import circuitWireLeft from "../../images/AboutUs/Banner/circuitWireLeft.svg";
//TODO: Add
import UpperBanner from "../../components/Common/EmpoweringBusinessesBanner/UpperBanner";
import { MPULSE_WEB_URL } from "../../Constants/Constant";
import dotsGroup from "../../images/Common/dotsGroup.svg";

const About: FC<any> = (): ReactElement => {
  return (
    <>
      <Container fluid className="bannerAbout">
        <Container className="gutter-Spacing-0 height-100">
          <Row className="padding-zero margin-none row-gutter-x-y-none cardPadding height-100">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="padding-zero bannerWithCircuitImage"
            >
              <div className="hero-banner-about">
                <h2 className="bannerSubTitle">Who we are</h2>
                <h1 className="bannerTitle text-align-left mar-btm-30px heroTitleFeedApi">
                  Transforming Businesses with Innovative Technologies
                </h1>
              </div>
              <Hidden smDown>
                <img
                  alt="circuitImage"
                  data-aos-easing="ease-in"
                  data-aos="fade-left"
                  // data-aos-anchor="#example-anchor"
                  data-aos-offset="500"
                  data-aos-duration="1000"
                  src={circuitImage}
                  className="digitalCircuitImage"
                />
              </Hidden>
            </Col>
          </Row>
        </Container>
        <Hidden smDown>
          <img
            alt="circuitImage"
            data-aos-easing="ease-in"
            data-aos="fade-left"
            // data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="1000"
            src={circuitWireLeft}
            className="digitalCircuitLeft"
          />
        </Hidden>
      </Container>
      <UpperBanner />

      {/** ----   Adding the caucasian male section here -----  */}

      <div className="sectionThree">
        <Container className="gutter-Spacing-0 ">
          <Row className="padding-none margin-none row-gutter-x-y-none cardPadding">
            <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
              <Image
                data-aos="slide-left"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                data-aos-once="true"
                className="d-block w-100"
                src={slide02}
                alt="First slide"
                fluid
                style={{ objectFit: "cover" }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="">
              <Card.Body
                className="cardCenter1"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos="zoom-in-up"
              >
                <Card.Title className="cardTitle">
                  Unlocking Customer Insights for <span>Business Growth</span>
                </Card.Title>
                <br />
                <Card.Text className="cardDescription mar-btm-30px">
                  We help you engage with your most valuable customers. By
                  harnessing the power of data, we enable you to understand
                  their needs better and create personalized experiences that
                  drive brand loyalty and growth.
                  <br />
                </Card.Text>
                <Link
                  key="Home"
                  to={`${MPULSE_WEB_URL}`}
                  color="transparent"
                  //target="_blank"
                >
                  <ScheduleDemoButton
                    btnTitle="Get Started"
                    btnClass="purpleBtn"
                  />
                </Link>
              </Card.Body>
            </Col>
          </Row>
        </Container>
        <img
          className="dotsGroupBottomRight"
          src={dotsGroup}
          alt="dots group"
        />
      </div>

      <div className="dashboards_reportings_BG1">
        <Container className="gutter-Spacing-0">
          <Row className="padding-none margin-none row-gutter-x-y-none cardPadding">
            <Col xs={12} sm={12} md={6} lg={6} className="">
              <Card.Body
                className="cardCenter1"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos="zoom-in-up"
              >
                <Card.Title className="cardTitle">
                  Digits that define <span>Growth</span>
                </Card.Title>
                <br />
                <Card.Text className="cardDescription mar-btm-30px">
                  Our flagship product <strong>mPulse</strong> offers real-time
                  data processing, providing you with actionable insights to
                  fuel your business's growth. Stay ahead of the competition
                  with intelligent recommendations and accurate forecasting.
                </Card.Text>
                <Link
                  key="Home"
                  to={`${MPULSE_WEB_URL}`}
                  color="transparent"
                  //target="_blank"
                >
                  <ScheduleDemoButton
                    btnTitle="Get Started"
                    btnClass="purpleBtn"
                  />
                </Link>
              </Card.Body>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
              <Image
                data-aos="slide-right"
                data-aos-easing="ease-in-out"
                data-aos-duration="700"
                data-aos-once="true"
                className="d-block w-100"
                src={slide03}
                alt="First slide"
                fluid
                style={{ objectFit: "cover" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/*------ Finished caucasian male and  section here ------ */}
      {/**Started working on the complete solution with intuitive dasboard section here */}
      <Banner />
      {/**Ended working on the complete solution with intuitive dasboard section here */}
      {/* <LowerBanner/> */}
    </>
  );
};

export default About;
