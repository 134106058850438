import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogsCard from "../BlogsSingleCard/BlogsCard";
import blogProfile from "../../../images/blogProfile.png";
import nextIcon from "../../../images/slideNextIcon.png";
import prevIcon from "../../../images/slidePrevIcon.png";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import "../Blogs.scss";

interface BlogSliderProps {
  blogsData: any;
}
const BlogSlider: React.FC<BlogSliderProps> = ({ blogsData }) => {

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    const arrowHeight = 50;
    const topValue = `calc(48% - ${arrowHeight / 2}px)`;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          zIndex: 99,
          right: "15px",
          top: topValue,
        }}
        onClick={onClick}
      >
        <img src={nextIcon} alt="Next Icon" />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    const arrowHeight = 50;
    const topValue = `calc(48% - ${arrowHeight / 2}px)`;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          zIndex: 99,
          left: "-15px",
          top: topValue,
        }}
        onClick={onClick}
      >
        <img src={prevIcon} alt="Previous Icon" />
      </div>
    );
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: blogsData.length >= 3 ? 3 : blogsData.length,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    initialSlide: 0,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow className="nextSlidde" />,
    prevArrow: <SamplePrevArrow className="prevSlidde" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: blogsData.length >= 3 ? 2 : blogsData.length,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: blogsData.length >= 3 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const handleViewAllClick = () => {
    window.scrollTo(0, 200);
  };

  const viewportWidth = window.innerWidth;

  return (
    <div className="blogSlider">
      <Container fluid className="row-gutter-x-y-none">
        <Container className="gutter-Spacing-0 height-100">
          <div className="justifySpaceBetween mar-btm-20px">
            <div className="blogSingleTitle ">All Blogs</div>
            <Link
              key="Blogs"
              to={`/blogs`}
              color="#000"
              onClick={handleViewAllClick}
              //target="_blank"
              className="viewAllLink"
            >
              View All
            </Link>
          </div>

          {viewportWidth >= 1024 && blogsData.length <= 3 ? (
            <Row
              className="padding-zero margin-none row-gutter-x-y-none cardPadding height-100"
              style={{ margin: "0 -10px 30px" }}
            >
              {blogsData.map((blog: any, index: number) => (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  key={blog.id}
                  className={`padding-12px slideInUp`}
                  style={{
                    animationDuration: `${1000 + index * 500}ms`,
                  }}
                >
                  <BlogsCard
                    className="BlogCard"
                    blogSrc={blog.cover_file}
                    // blogSrc={blog.cover_image}
                    blogProfileImg={blogProfile}
                    profileName={blog.summary ? blog.summary : "Blog User"}
                    date={moment(blog.created_at).format("MMM DD, YYYY")}
                    blogLink="blog-single"
                    blogText={blog.title}
                    style={{ margin: "10px" }}
                    blogId={blog.id}
                    slug={blog.slug}
                  />
                </Col>
              ))}{" "}
            </Row>
          ) : (
            <Row
              className="padding-zero margin-none row-gutter-x-y-none cardPadding height-100"
              style={{ margin: "0 -10px 30px" }}
            >
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="padding-zero"
              >
                <Slider {...settings}>
                  {blogsData.map((blog: any, index: number) => (
                    <div key={index}>
                      {" "}
                      <BlogsCard
                        className="BlogCardDesc"
                        blogSrc={blog.cover_file}
                        blogProfileImg={blogProfile}
                        profileName={blog.summary ? blog.summary : "Blog User"}
                        date={moment(blog.created_at).format("MMM DD, YYYY")}
                        blogLink="blog-single"
                        blogText={blog.title}
                        style={{ margin: "10px" }}
                        blogId={blog.id}
                        slug={blog.slug}
                      />
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          )}
        </Container>
      </Container>
    </div>
  );
};

export default BlogSlider;
