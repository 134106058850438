import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import noPageFoundImg from "../../../images/Common/page_not_found.svg"
import "./style.scss"

const NotFound404 = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(-1);
    };
    return (
        <Box>
            <Box className="container--not-found">
                <Box className="sub--container--not-found">
                    <img src={noPageFoundImg} alt="page not found" />
                    <Typography variant="h3" className="fontHeading2">
                        Sorry! This Page isn't available.
                    </Typography>
                    <Typography variant="h5" className="subheading">
                        The link you have followed may be broken or the page may have been
                        removed.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleNavigation}
                        className="backbutton"
                        size="large"
                        startIcon={<KeyboardBackspaceIcon />}
                    >
                        Back
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default NotFound404
