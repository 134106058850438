import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { cardsData } from "./CardsData";
import dotsGroup from "../../images/Common/dotsGroup.svg";

const Banner = () => {
  return (
    <Container fluid className="bannerUpperlavender cardPadding">
      <img className="dotsGroupTopLeft" src={dotsGroup} alt="dots group" />

      <div className="banner-overlay1">
        <Container>
          <div
            className="headingCSS"
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-once="true"
          >
            <h1 className="mar-btm-30px">
              The Complete
              <span className="headingSpan"> Solution</span>
            </h1>
          </div>
          <p
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-once="true"
            className="cardDescription"
          >
            mMatrix offers a cutting-edge product mPulse designed to maximize
            your potential and drive success in the digital age. Our solution
            empowers businesses to make informed decisions, uncover growth
            opportunities, and stay ahead of the competition.
          </p>
        </Container>
        <br />
        <Container className="cardsContainer">
          <Row>
            {cardsData.map((item, index) => (
              <Col
                key={index}
                xs={12}
                sm={12}
                md={12}
                lg={4}
                data-aos="slide-up"
                data-aos-easing="ease-forwards"
                data-aos-duration={`${500 + index * 500}`}
                data-aos-once="true"
              >
                <Card className="cardBanner">
                  <Card.Body className="cardBody-2">
                    <div className="cardHeadDiv1 mar-btm-30px">
                      <div className="cardIconCSS1">
                        <Image
                          style={{
                            width: "100%",
                            height: "270px",
                            flexShrink: 0,
                          }}
                          className="card-icon1"
                          src={item.cardIcon}
                          alt="First slide"
                        />
                      </div>
                      <br />
                      <Card.Title
                        className="cardTitle1"
                        style={{ textAlign: "left" }}
                      >
                        {item.title}
                      </Card.Title>
                    </div>
                    <Card.Text
                      className="cardDesc pad-x-sm"
                      style={{ textAlign: "left" }}
                    >
                      {item.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <img className="dotsGroupBottomRight" src={dotsGroup} alt="dots group" />
    </Container>
  );
};

export default Banner;
