// pages
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Blogs from "./pages/Blogs/BlogsPage/Blogs";
import Products from "./pages/Products";
import BlogSingle from "./pages/Blogs/BlogsSinglePage/BlogsSingle";
import FeedApi from "./pages/FeedApi/FeedApi";
import { FC } from "react";
import { MPULSE_APPLICATION_URL, MPULSE_WEB_URL } from "./Constants/Constant";

// interface
interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: "home-route",
    title: "",
    path: "/",
    enabled: true,
    component: Home,
  },
  {
    key: "about-route",
    title: "About Us",
    path: "/about",
    enabled: true,
    component: About,
  },
  {
    key: "products-route",
    title: "Product",
    path: `${MPULSE_WEB_URL}`,
    enabled: true,
    component: Products,
  },
  {
    key: "data-feed-api-route",
    title: "Feed API",
    path: `/feedApi`,
    enabled: true,
    component:FeedApi,
  },
  {
    key: "blogs-route",
    title: "Blogs",
    path: "/blogs",
    enabled: true,
    component: Blogs,
  },
  {
    key: "blog-single-route",
    title: "Blog Single",
    path: "/blog-single/:slug",
    enabled: true,
    component: BlogSingle,
  },
  // {
  //   key: "support-route",
  //   title: "Support",
  //   path: "/products",
  //   enabled: true,
  //   component: Products,
  // },
  //   {
  //     key: "products-route",
  //     title: "FAQs",
  //     path: "/products",
  //     enabled: true,
  //     component: Products,
  //   },
];

export const navLoginRoutes: Array<Route> = [
  // {
  //   key: "mPulse-route",
  //   title: "mPulse",
  //   path: `${process.env.REACT_APP_MPULSE_WEB_URL}`,
  //   enabled: true,
  //   component: About,
  // },
  {
    key: "login-route",
    title: "Login",
    path: `${MPULSE_APPLICATION_URL}/auth/sign-in`,
    enabled: true,
    component: About,
  },
  {
    key: "get-started-route",
    title: "Get Started",
    path: `${MPULSE_WEB_URL}`,
    enabled: true,
    component: About,
  },
];
export const footerRoutes: Array<Route> = [
  {
    key: "footer-home-route",
    title: "Home",
    path: "/",
    enabled: true,
    component: Home,
  },
  {
    key: "footer-about-us-route",
    title: "About us",
    path: "/about",
    enabled: true,
    component: About,
  },
  {
    key: "footer-blogs-route",
    title: "Blogs",
    path: "/blogs",
    enabled: true,
    component: Blogs,
  },
  {
    key: "footer-products-route",
    title: "Products",
    path: `${MPULSE_WEB_URL}`,
    enabled: true,
    component: Products,
  },
  // {
  //   key: "terms-conditions-route",
  //   title: "Terms & Conditions",
  //   path: "/about",
  //   enabled: true,
  //   component: About,
  // },
  // {
  //   key: "privacy-policy-route",
  //   title: "Privacy Policy",
  //   path: "/about",
  //   enabled: true,
  //   component: About,
  // },
];
