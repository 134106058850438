import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import engageDataEmpowerBusiness from "../../../images/LandingPage/engageData.png";
import blueCircle from "../../../images/LandingPage/smallDotsCricle.png";
import { Hidden } from "@mui/material";
import ScheduleDemoButton from "../../Common/ScheduleButton/ScheduleDemoButton";
import { MPULSE_WEB_URL } from "../../../Constants/Constant";

const renderCardDescription = (description: any) => (
  <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
    <div className="mar-btm-20px">
      <div className="cardDescription p-tag-left">
        <ul>
          <li>{description}</li>
        </ul>
      </div>
    </div>
  </Col>
);

const renderImageSection = () => (
  <Col
    xs={12}
    sm={12}
    md={8}
    lg={6}
    className="padding-zero flexAlignCenterNew"
  >
    <div
      data-aos="zoom-in-down"
      data-aos-easing="ease-in-out"
      data-aos-duration="1500"
      data-aos-once="true"
      className="container-rotation"
    >
      <img
        className="animationCircle rotate-XY"
        src={blueCircle}
        alt="outer circle"
      />
      <Image
        className="d-block w-100 pivotImage"
        src={engageDataEmpowerBusiness}
        alt="Engage Data Empower Business"
        fluid
        style={{ objectFit: "cover" }}
      />
    </div>
  </Col>
);

const EngageYourDataSection = () => {
  return (
    <div className="dataCollectionBG">
      <Container>
        <Row className="padding-none margin-none row-gutter-x-y-none cardPadding">
          <Col xs={12} sm={12} md={12} lg={6}>
            <div
              data-aos="slide-up"
              data-aos-easing="ease-in-out"
              data-aos-duration="1000"
              className="height-100 paddingLeft"
              data-aos-once="true"
            >
              <Card.Body className="cardCenter">
                <Card.Title className="cardTitle mar-btm-20px">
                  <span>Engage your Data.</span> Empower your Business.
                </Card.Title>
                <Card.Text className="cardDescription mar-btm-30px p-tag-left">
                  Unlock the power of actionable insights from your data and
                  gain a competitive edge.
                </Card.Text>
                <Row className="padding-none margin-none row-gutter-x-y-none">
                  {renderCardDescription(
                    "Innovate and adapt swiftly to changing market dynamics"
                  )}
                  {renderCardDescription(
                    "Leverage intelligent recommendations for strategic planning"
                  )}
                  {renderCardDescription(
                    "Mitigate risks by anticipating market shifts and customer behavior"
                  )}
                  {renderCardDescription(
                    "Identify true potential of your data"
                  )}
                </Row>
                <Card.Text className="cardDescription mar-btm-30px p-tag-left">
                  Elevate your decision-making process with real-time insights,
                  driving your business towards success.
                </Card.Text>
                <Hidden mdUp>{renderImageSection()}</Hidden>
                <Link key="Home" to={`${MPULSE_WEB_URL}`} color="transparent">
                  <ScheduleDemoButton
                    btnTitle="Get Started"
                    btnClass="purpleBtn"
                  />
                </Link>
              </Card.Body>
            </div>
          </Col>
          <Hidden mdDown>{renderImageSection()}</Hidden>
        </Row>
      </Container>
    </div>
  );
};

export default EngageYourDataSection;
