import slide01 from "../../../images/Integrated.svg";
import slide02 from "../../../images/intuitive.svg";
import slide03 from "../../../images/Flexible.svg";
import slide04 from "../../../images/Efficient.svg";

export const cardsData = [
  {
    title: "Integrated",
    cardIcon: slide01,
    description:
      "Our solutions seamlessly combine data analytics, machine learning and AI to deliver comprehensive and actionable insights",
  },
  {
    title: "Intuitive",
    cardIcon: slide02,
    description:
      " With user-friendly interfaces and easy-to-understand reports, our tools empower your team to make informed decisions confidently.",
  },
  {
    title: "Flexible",
    cardIcon: slide03,
    description:
      "Tailor your marketing strategies to meet your unique business goals with our customizable solutions.",
  },
  {
    title: "Efficient",
    cardIcon: slide04,
    description:
      "Boost your marketing ROI with our streamlined solutions, removing bottlenecks so you can focus on connecting with your customers.",
  },
];
