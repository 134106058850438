import { Container } from "react-bootstrap";
import ScheduleDemoButton from "../ScheduleButton/ScheduleDemoButton";
import { Image } from "react-bootstrap";
// import slide01 from "../images/banner_image.png";
import dashboardImage from "../../../images/LandingPage/dashboard.png";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MPULSE_WEB_URL } from "../../../Constants/Constant";
import circleBottomLeft from "../../../images/LandingPage/bottomLeft.png";
import circleTopRight from "../../../images/LandingPage/topRightCircle.png";
import LeftSpringImg from "../../../images/curl_1.png";
import RightSpringImg from "../../../images/curl_2.png";
import "./style.scss";

const Banner = () => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageHeight, setImageHeight] = useState<any>(null);

  useEffect(() => {
    const handleImageLoad = () => {
      if (imageRef.current) {
        const height = imageRef.current.clientHeight;
        // console.log("Image height:", height);
        setImageHeight(height);
      }
    };

    const handleWindowResize = () => {
      if (imageRef.current) {
        const height = imageRef.current.clientHeight;
        setImageHeight(height);
        // console.log("Image height on resize:", height);
      }
    };

    if (imageRef.current) {
      imageRef.current.addEventListener("load", handleImageLoad);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      if (imageRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        imageRef.current.removeEventListener("load", handleImageLoad);
      }
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <Container fluid className="bannerHome">
        <Container className="bannerDiv">
          <div className="hero-banner">
            <div>
              <h1 className="bannerTitle mar-btm-30px">
                Harnessing the Power of Data, Machine Learning, and AI for
                Smarter Decisions
              </h1>
              <h3 className="bannerSubTitle mar-btm-30px">
                At mMatrix, the future of successful businesses lies in their
                ability to leverage data effectively.
              </h3>
              <Link
                key="Home"
                to={`${MPULSE_WEB_URL}`}
                color="transparent"
                //target="_blank"
              >
                <ScheduleDemoButton
                  btnTitle="Get Started"
                  btnClass="whiteBtn"
                />
              </Link>
            </div>
          </div>
        </Container>
      </Container>
      <Container
        fluid
        className="homeBannerLowerSEction"
        style={{ height: (imageHeight * 6) / 10 }}
      >
        <img src={LeftSpringImg} className="leftSpring" alt="top clip" />
        <img
          src={RightSpringImg}
          className="rightSpring"
          alt="top clip right"
        />

        <div className="imageCut">
          <div className="imgFlexCenter ">
            <img src={circleBottomLeft} className="leftCirle" alt="top clip" />
            <img src={circleTopRight} className="rightCirle" alt="top clip" />
            <Image
              ref={imageRef}
              className="d-block imgBanner zoomIn"
              src={dashboardImage}
              alt="First slide"
              fluid
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default Banner;
