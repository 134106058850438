import React, { ReactElement, FC, useEffect, useState } from "react";
import { Image, Card, Col, Container, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setBlogs, setCurrentBlog } from "../../../store/slices/blogSlice";
import { fetchBlogs } from "../../../store/slices/api";
import blogDate from "../../../images/blogDate.png";
import BlogSlider from "../BlogsSlider/BlogSlider";
import bullets from "../../../images/bullets_top_bottom.png";
import moment from "moment";
import DOMPurify from "dompurify";
import { Box, Skeleton } from "@mui/material";
import "../Blogs.scss";

import axios from "axios";
import NotFound404 from "../../../components/Common/PageNotFound";

const BASE_URL = process.env.REACT_APP_API_URL

interface Blog {
  id: number;
  title: string;
  meta_title: string;
  slug: string;
  summary: string;
  published: boolean;
  created_at: string;
  updated_at: string;
  published_at: string;
  content: string;
  cover_image: string;
  cover_file: string;
  parent: string;
}

const BlogsSingle: FC<any> = (): ReactElement => {
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();

  const dispatch = useDispatch();
  const [blogSingle, setBlogSingle] = useState<Blog | null>(null);
  const [blogFound, setBlogFound] = useState(true);        // Added state to track blog validity
  const blogs: any = useSelector((state: any) => state.blogSlice.blogs);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBlogs("blogs/list_blogs/");
        dispatch(setBlogs(data.posts));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const selectedBlog = blogs.find((blog: any) => blog.slug === slug);
    dispatch(setCurrentBlog(selectedBlog));
    if (!selectedBlog) {
      setBlogFound(false); // If no matching blog, set blogFound to false
    }
  }, [slug, blogs, dispatch]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);

        const response = await axios.post(`${BASE_URL}/blogs/get_blog_slug/`, {
          slug,
        });
        if (response.data) {
          setBlogSingle(response.data);
          setBlogFound(true); // Valid blog found
        } else {
          setBlogFound(false); // No blog found
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
        setBlogFound(false); // Handle error by showing NotFound404
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [slug]);

  if (!blogFound && !loading) {
    return <NotFound404 />; // Render NotFound404 if blog is not found
  }

  if (loading) {
    return (
      <Box
        className="blogs_loading_div"
      // sx={{
      //   flexGrow: 1,
      //   backgroundColor: "whitesmoke",
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "flex-start",
      //   height: "100%",
      //   width: "100%",
      // }}
      >
        <Container className="">
          <Row className="alignItemsCenter row-gutter-x-y-none">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={`padding-12px`}
            >
              {" "}
              <br />
              <Typography variant="h1">
                <Skeleton />
              </Typography>
              <Typography variant="caption">
                <Skeleton width="25%" />
              </Typography>
              <br />
              <Skeleton
                variant="rectangular"
                width="100%"
                height="40vh"
                className="blogsSingleCard"
              />
              <br />
            </Col>
            {[...Array(3)].map((_, index) => (
              <Col
                key={index}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={`padding-12px`}
              >
                <Typography variant="caption">
                  <Skeleton />
                </Typography>
              </Col>
            ))}
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={`padding-12px`}
            >
              <Typography variant="caption">
                <Skeleton width="40%" />
              </Typography>
            </Col>
          </Row>
          <br />
          <br />
        </Container>
      </Box>
    );
  }

  return (
    <>
      <div className="blogsSection_BG blogsSingleSection_BG relativePositionCSS">
        <Image
          className="bullet_top absolutePositionCSS"
          src={bullets}
          alt="bullet_top"
        />
        <Container>
          <Row className="alignItemsCenter row-gutter-x-y-none">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="padding-12px"
            >
              <Card.Text className="blogSingleTitle">
                {blogSingle != null
                  ? blogSingle.title
                    ? blogSingle.title
                    : "Null Title"
                  : ""}
              </Card.Text>
              <hr className="hrTitle" />
              <div>
                <div className="blogInfoSection">
                  <div className="infoProfile">
                    <Image
                      // style={{ height: "auto", flexShrink: 0 }}
                      className="card-icon1 blogImage_div"
                      src={blogDate}
                      alt="Date"
                    />
                    <span>
                      {blogSingle != null
                        ? blogSingle.created_at
                          ? moment(blogSingle.created_at).format("MMM DD, YYYY")
                          : moment(new Date()).format("MMM DD, YYYY")
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <hr className="hrTitle" />
              <div className="blogsSingleCard">
                <Card.Body>
                  <div className="blogImage">
                    {blogSingle && (
                      <Image
                        // style={{ width: "100%", height: "auto", flexShrink: 0 }}
                        className="card-icon1 blogImage_div"
                        // src={blogSingle.cover_image}
                        src={blogSingle.cover_file}
                        alt="Blog_Cover_Image"
                      />
                    )}
                  </div>
                </Card.Body>
              </div>
              <div className="blogDescription">
                {blogSingle != null ? (
                  blogSingle.content ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(blogSingle.content),
                      }}
                    />
                  ) : (
                    "Null Content"
                  )
                ) : (
                  ""
                )}
              </div>
            </Col>

            {blogs.length > 0 && (
              <BlogSlider
                blogsData={blogs.filter((blog: any) => {
                  return blog.slug !== slug;
                })}
              />
            )}
          </Row>
        </Container>
        <Image className="bullet_bottom" src={bullets} alt="bullet_top" />
      </div>
    </>
  );
};

export default BlogsSingle;
