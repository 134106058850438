import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { cardsData } from "./EmpoweringBanner";

const Banner = () => {

  return (
    <Container fluid className="bannerUpper cardPadding">
      <div className="banner-overlay1">
        <Container className="bannerText">
          <div
            className="headingCSS"
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="1000"
            // data-aos-delay="5"
            data-aos-once="true"
          >
            <h1 className="mar-btm-30px">
              Empowering Smarter Decisions with <br></br>
              <span className="headingSpan"> mMatrix Solutions</span>
            </h1>
          </div>
          <p
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="1200"
            // data-aos-delay="5"
            data-aos-once="true"
            className=""
          >
            Empower yourself with cutting-edge products that enable better
            decision-making, fuel growth, and outshine the competition.
          </p>
        </Container>

        <Container className="cardsContainer">
          <Row className="cardSectionRow ">
            {cardsData.map((item, index) => (
              <Col
                xs={12}
                sm={12}
                md={12}
                xl={4}
                lg={4}
                className={`cardSection`}
              >
                {" "}
                <div
                  data-aos="slide-up"
                  data-aos-easing="ease-forwards"
                  data-aos-duration={`${1000 + index * 500}`}
                  data-aos-once="true"
                >
                  <Card className="cardBanner">
                    <Card.Body className="cardBody">
                      <div className="cardHeadDiv">
                        <div className="cardIconCSS ">
                          <Image
                            className="card-icon white-icon"
                            src={item.cardIcon}
                            alt="First slide"
                          />
                        </div>
                        <Card.Title className="cardTitle">
                          {item.title}
                        </Card.Title>
                      </div>
                      <Card.Text className="cardDesc">
                        {item.description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default Banner;
