import { Grid } from "@mui/material";
import DataCard from "./DataCard";
import { cardsData } from "./DataCardObj";
import "./style.scss";

const DataCardContainer = ({ data }: any) => {

  return (
    <Grid
      className="dataCardGrid"
      // sx={{ mt: 2.5, maxWidth: "1340px" }}
      container
      spacing={{ lg: 2, md: 2, xs: 2, sm: 2 }}
    >
      {cardsData?.map((item: any) => (
        <Grid
          className="dataGrid"
          item
          lg={4}
          sm={6}
          xs={12}
        >
          <DataCard
            cardIcon={item.cardIcon}
            title={item.title}
            description={item.description}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DataCardContainer;
