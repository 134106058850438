import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { cardsData } from "./UpperBusinessBanner";
import dotsGroup from "../../../images/Common/dotsGroup.svg";

const Banner = () => {

  return (
    <Container fluid className="bannerUpper cardPadding">
      <img className="dotsGroupTopLeft" src={dotsGroup} alt="dots group" />

      <div className="banner-overlay1">
        <Container>
          <div
            className="headingCSS"
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-once="true"
          >
            <h1 className="mar-btm-30px">
              We Empower Businesses with Data-Driven <br />
              <span className="headingSpan"> Marketing Solutions</span>
            </h1>
          </div>
          <p
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="800"
            data-aos-once="true"
            className=""
          >
            We are passionate about empowering businesses with data-driven
            marketing solutions. Our integrated, intuitive, flexible, and
            efficient approach helps you build a customer-centric marketing
            strategy that drives success in today's dynamic marketplace.
          </p>
        </Container>

        <Container>
          <Row className="alignItemsCenter">
            {cardsData.map((item, index) => (
              <Col
                xs={12}
                sm={12}
                md={6}
                xl={3}
                lg={6}
                className="cardSection"
                data-aos="slide-up"
                data-aos-easing="ease-forwards"
                data-aos-duration={`${500 + index * 500}`}
                data-aos-once="true"
              >
                <Card className="cardBanner">
                  <Card.Body className="cardBody">
                    <div className="cardHeadDiv">
                      <div className="cardIconCSS">
                        <Image
                          className="card-icon"
                          src={item.cardIcon}
                          alt="First slide"
                        />
                      </div>
                      <Card.Title className="cardTitle">
                        &nbsp;&nbsp;{item.title}
                      </Card.Title>
                    </div>
                    <Card.Text className="cardDesc">
                      {item.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default Banner;
