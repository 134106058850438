import { Box } from "@mui/material";
import heroImage from "../../images/FeedApi/heroImage.png";
import "../FeedApi/feedApiStyle.scss";

const FeedApiHeroSection = () => {
  return (
    <Box className="feedApi--hero-section">
      <Box className="feedApi--hero-container">
        <Box className="feedApi--hero-content">
          <Box className="feedApi--hero-text">
            <h1 className="bannerTitle text-align-left mar-btm-30px heroTitleFeedApi">
              Data Feed API
            </h1>
            <h2 className="bannerSubTitle subtitle--feedapi">
              Seamlessly integrate our API and access real-time Social Listening
              insights to fuel your brand's success.
            </h2>
          </Box>

          <Box className="feedApi--hero-image">
            <img
              className="heroImage--feedApi"
              src={heroImage}
              alt="feed api hero"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FeedApiHeroSection;
