// src/data/cardsData.ts
import slide01 from "../../images/leverage_power_of_data.png";
import slide02 from "../../images/harness_the_potential_AI.png";
import slide03 from "../../images/drive_growth_with_ml.png";

export const cardsData = [
  {
    title: "Leverage the Power of Data",
    cardIcon: slide01,
    description:
      "Our data-driven solutions enable you to unlock the potential of your data. From data collection to advanced analytics, we equip you with the insights you need to make informed decisions",
  },
  {
    title: "Harness the Potential of Artificial Intelligence",
    cardIcon: slide02,
    description:
      "Our AI capabilities empower your business to optimize processes, predict trends, and enhance customer experiences. Embrace the power of artificial intelligence to stay ahead in a dynamic marketplace.",
  },
  {
    title: "Drive Growth with Machine Learning",
    cardIcon: slide03,
    description:
      "With cutting-edge machine learning algorithms, we help you anticipate trends, understand customer behavior, and optimize your strategies for maximum impact.",
  },
];
